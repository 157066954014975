<template>
    <div>
      <div class="px-4 overflow-y-auto" :style="`max-height: ${tableHeight}`">
        <table cellspacing="0" class="text-center w-full analytics-table">
          <thead>
            <tr>
              <td v-if="showIndex" class="p-2 font-semibold text-lg border border-t-0 border-r-0 border-l-0 border-grey border-solid text-grey-dark">
                {{ '#' }}
              </td>
              <td v-for="(label, labelsIndex) in tableHeaders" :key="labelsIndex" :class="showIndex && labelsIndex === 0 ? 'text-left' : ''" class="p-2 font-semibold text-lg text-primary border border-t-0 border-r-0 border-l-0 border-grey border-solid whitespace-no-wrap">
                {{ label.text }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(item, index) in citiesData">
              <td class="text-lg border border-t-0 border-r-0 border-l-0 border-grey-light border-dotted p-2 text-grey-dark" v-if="showIndex">
                {{ index + 1 + '.'}}
              </td>
              <td v-for="(label, labelsIndex) in tableHeaders" :key="labelsIndex" :class="showIndex && labelsIndex === 0 ? 'text-left' : ''" class="text-grey-dark text-lg border border-t-0 border-r-0 border-l-0 border-grey-light border-dotted p-2">
                {{ labelsIndex === tableHeaders.length - 1 ? item[label.field] + '%' : $t(item[label.field]) }}
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="citiesData.length === 0" class="text-center mt-4 text-grey-dark">No Data Available</p>
      </div>
    </div>
</template>
<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['citiesData', 'tableHeaders', 'tableHeight', 'showIndex'],
  components: {
    ShipbluCard
  }
}
</script>

<style>
.analytics-table thead {
  position: sticky;
  top: 0;
  background: white;
}
table.analytics-table tr td:first-child {
  text-align: left;
}
</style>